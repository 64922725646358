import React, { useEffect, useState } from "react";
import axios from "axios";
import { FaUserTimes, FaUserCheck, FaBed, FaClock, FaUsers, FaHome, FaClipboard, FaCross, FaExclamationCircle, FaCheck, FaList } from 'react-icons/fa';
import { useNavigate } from "react-router-dom";

const Card = ({ backcolor, icon, bgColor, title, subtitle, onClick }) => {
  return (
    <div style={{ backgroundColor: backcolor, transition: 'opacity 0.3s ease' }} className={`flex flex-col items-center justify-center p-6 shadow-lg rounded-lg border-black hover:shadow-xl transition duration-300 ease-in-out`} onClick={onClick}>
      <div className={`flex items-center justify-center h-16 w-16 rounded-full mb-4 shadow-md border border-gray-500`} style={{ backgroundColor: bgColor }}>
        {icon}
      </div>
      <div className="text-center">
        <h3 className="text-xl font-bold text-gray">{title}</h3>
        <p className="text-gray text-lg font-bold">{subtitle}</p>
      </div>
    </div>
  );
};

const RecruitmentDetails = () => {
  const [pendingCount, setPendingCount] = useState("");
  const [inReviewCount, setInReviewCount] = useState("");
  const [rejectedCount, setRejectedCount] = useState("");
  const [acceptedCount, setAcceptedCount] = useState("");
  const [allApplicantsCount, setAllApplicantCount] = useState("");
  const navigate = useNavigate()
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('https://backendint.marsbpo.org/api/recruitment-forms/applicant-summary', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        const data = response.data;
        setPendingCount(parseInt(data.pending));
        setInReviewCount(parseInt(data.inReview));
        setRejectedCount(parseInt(data.rejected));
        setAcceptedCount(parseInt(data.accepted));
        setAllApplicantCount(parseInt(data.all));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
    fetchData();
  }, []);

  const handlePendingCardClick = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`https://backendint.marsbpo.org/api/recruitment-forms/pending`,{
        headers:{
          Authorization: `Bearer ${token}`
        }
      });
      const pendingApplicants = response.data.applicants; // Update here
      navigate('/pending-applicants-details', { state: { applicants: pendingApplicants } }); // Update here
    } catch (error) {
      console.error("Error fetching pending applicants:", error);
    }
  };
  
  const handleInReviewCardClick = async () => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.get(`https://backendint.marsbpo.org/api/recruitment-forms/in-review`, {
        headers:{
          Authorization: `Bearer ${token}`
        }
      });
      const inReviewApplicants = response.data.applicants;
            navigate('/in-review-applicants-details', { state: {applicants: inReviewApplicants } });
    } catch (error) {
      console.error("Error fetching in-review applicants:", error);
    }
  };

  const handleAllCardClick = async () => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.get(`https://backendint.marsbpo.org/api/recruitment-forms/all-applicants`, {
        headers:{
          Authorization: `Bearer ${token}`
        }
      });
      const allApplicants = response.data.applicants;
            navigate('/all-applicants-details', { state: {applicants: allApplicants } });
    } catch (error) {
      console.error("Error fetching in-review applicants:", error);
    }
  };

  const handleRejectedCardClick = async () => {
    try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`https://backendint.marsbpo.org/api/recruitment-forms/rejected`,{
          headers:{
            Authorization: `Bearer ${token}`
          }
        });
        const rejectedApplicants = response.data.applicants; // Update here
        navigate('/rejected-applicants-details', { state: { applicants: rejectedApplicants } }); // Update here
      } catch (error) {
        console.error("Error fetching rejected applicants:", error);
      }
  };
  const handleAcceptedCardClick = async () => {
    try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`https://backendint.marsbpo.org/api/recruitment-forms/accepted`,{
          headers:{
            Authorization: `Bearer ${token}`
          }
        });
        const acceptedApplicants = response.data.applicants; // Update here
        navigate('/accepted-applicants-details', { state: { applicants: acceptedApplicants } }); // Update here
      } catch (error) {
        console.error("Error fetching accepted applicants:", error);
      }
  };

  return (
    <div>
      <section className="grid md:grid-cols-2 xl:grid-cols-5 gap-6">
      <Card
          backcolor="rgba(96, 165, 250, 0.2)"
          icon={<FaClock color="rgba(96, 165, 250, 0.8)" size={32} />}
          bgColor="#fff"
          title={pendingCount}
          subtitle="Pending Applicants"
          onClick={handlePendingCardClick}
        />
        <Card
        backcolor="rgba(245, 158, 11, 0.2)"
          icon={<FaClipboard color="rgba(245, 158, 11, 0.6)" size={32} />}
          bgColor="#fff"
          onClick={handleInReviewCardClick}
          title={inReviewCount}
          subtitle="In Review Applicants"
        />
        <Card
        backcolor="rgba(248, 113, 113, 0.2)"
          icon={<FaExclamationCircle color="rgba(248, 113, 113, 0.8)" size={32} />}
          bgColor="#fff"
          title={rejectedCount}
          onClick={handleRejectedCardClick}
          subtitle="Rejected Applicants"
        />
        <Card
        backcolor="rgba(74, 222, 128, 0.2)"
          icon={<FaCheck  color="rgba(74, 222, 128, 0.8)"size={32} />}
          bgColor="#fff"
          title={acceptedCount}
          onClick={handleAcceptedCardClick}
          subtitle="Accepted Applicants"
        />
        <Card
        backcolor="rgba(88, 29, 251, 0.2)"
          icon={<FaList  color="rgba(88, 29, 251, 0.5)"size={32} />}
          bgColor="#fff"
          title={allApplicantsCount}
          onClick={handleAllCardClick}
          subtitle="All Applicants"
        />
      </section>
    </div>
  );
};

export default RecruitmentDetails;
