import React, { useState, useRef } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const FileUpload = () => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [serverErrors, setServerErrors] = useState('');

  const fileInputRef = useRef(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setError('');
  };

  const handleUpload = async () => {
    if (!file) {
      setError('Please select a file before uploading.');
      return;
    }
  
    const formData = new FormData();
    formData.append('file', file);
  
    try {
      setLoading(true);
      const token = localStorage.getItem('token');
  
      const response = await fetch('https://backendint.marsbpo.org/api/employees/upload-employees', {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      const responseData = await response.json();
  
      if (response.ok) {
        toast.success('File uploaded successfully');
        setFile(null);
        setLoading(false);
        setError(null);
      } else {
        if (responseData && responseData.errors) {
          const errorMessages = responseData.errors.map((error) => error.message);
          setServerErrors(`Error uploading file: ${errorMessages.join(', ')}`);
        } else {
          setError(responseData.message || 'Error uploading file.');
        }
        setLoading(false);
      }
    } catch (error) {
      setError('Error uploading file. Please try again.');
      setLoading(false);
      console.error('Error uploading file:', error);
    }
  };

  const openFileDialog = () => {
    fileInputRef.current.click();
  };

  return (
    <div style={{ backgroundColor: "#F4F4F4" }} className="flex flex-col items-center">
       <ToastContainer />
      {serverErrors && <p className="text-red-500 mt-2">{serverErrors}</p>}

      <div className="w-full h-40 border-dashed border-2 border-black rounded-md cursor-pointer" onClick={openFileDialog}>
        <input type="file" ref={fileInputRef} onChange={handleFileChange} accept=".xlsx" className="hidden" />
        <p className="text-center pt-12">
          {file ? `File selected: ${file.name}` : 'Click to select Excel file'}
        </p>
      </div>
      <button className={`mt-4 bg-blue-950 py-2 px-4 rounded text-white ${loading || !file ? 'opacity-50 cursor-not-allowed' : ''}`} onClick={handleUpload} disabled={loading || !file}>
        {loading ? 'Uploading...' : 'Upload'}
      </button>
      <button className="mt-4 p-2 border border-gray-300 rounded cursor-pointer" onClick={openFileDialog}>
        Select Excel File
      </button>

      {error && <p className="text-red-500 mt-2">{error}</p>}
    </div>
  );
};

export default FileUpload;
