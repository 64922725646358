import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SendNotification = () => {
    const [notificationMessage, setNotificationMessage] = useState("");
    const [pcs, setPCs] = useState([]);
    const [selectedPCs, setSelectedPCs] = useState([]);

    useEffect(() => {
        const fetchPCs = async () => {
            try {
                const response = await axios.get('https://backendint.marsbpo.org/api/pc/getAllPCs');
                setPCs(response.data);
            } catch (error) {
                console.error('Error fetching PCs:', error);
                toast.error('Failed to fetch PCs');
            }
        };
        fetchPCs();
    }, []);

    const handleSendNotification = async (e) => {
        e.preventDefault();
        try {
            if (!selectedPCs.length) {
                toast.error('Please select at least one PC');
                return;
            }
            for (const pcId of selectedPCs) {
                await axios.post('https://backendint.marsbpo.org/api/notification/send', {
                    message: notificationMessage,
                    targetPcId: pcId
                });
            }
            setNotificationMessage("");
            setSelectedPCs([]);
            toast.success('Notifications sent successfully');
        } catch (error) {
            console.error('Error sending notifications:', error);
            toast.error('Failed to send notifications');
        }
    };

    const handleCheckboxChange = (e) => {
        const pcId = parseInt(e.target.value);
        if (e.target.checked) {
            setSelectedPCs([...selectedPCs, pcId]);
        } else {
            setSelectedPCs(selectedPCs.filter(id => id !== pcId));
        }
    };

    const handleSelectAll = () => {
        const allPCIds = pcs.map(pc => pc.pcId);
        setSelectedPCs(allPCIds);
    };

    const handleDeselectAll = () => {
        setSelectedPCs([]);
    };

    return (
        <div className="container mx-auto mt-10">
            <div className="max-w-xl mx-auto bg-white shadow-md rounded px-8 py-8">
                <h2 className="text-lg font-semibold mb-6">Send Notification to PCs</h2>
                <form onSubmit={handleSendNotification}>
                    <div className="mb-6">
                        <label htmlFor="notificationMessage" className="block text-gray-700 text-sm font-bold mb-2">Notification Message:</label>
                        <textarea
                            id="notificationMessage"
                            value={notificationMessage}
                            onChange={(e) => setNotificationMessage(e.target.value)}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline resize-none"
                            rows="4"
                            required
                        />
                    </div>
                    <div className="mb-6">
                        <p className="text-gray-700 font-bold mb-2">Select PCs:</p>
                        <table className="w-full">
                            <tbody>
                                {pcs.map(pc => (
                                    <tr key={pc.pcId}>
                                        <td>
                                            <input
                                                type="checkbox"
                                                id={`pc_${pc.pcId}`}
                                                value={pc.pcId}
                                                onChange={handleCheckboxChange}
                                                checked={selectedPCs.includes(pc.pcId)}
                                            />
                                        </td>
                                        <td>
                                            <label htmlFor={`pc_${pc.pcId}`} className="ml-2">{pc.pcName}</label>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="flex justify-between items-center">
                        <div>
                        <button
    type="button"
    onClick={handleSelectAll}
    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline mr-2 text-xs"
>
    Select All
</button>
<button
    type="button"
    onClick={handleDeselectAll}
    className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline text-xs"
>
    Deselect All
</button>
                        </div>
                        <button
                            type="submit"
                            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-3 rounded focus:outline-none focus:shadow-outline"
                        >
                            Send Notification
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default SendNotification;
