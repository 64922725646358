import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Home = () => {
  const [employeeData, setEmployeeData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEmployeeData = async () => {
      try {
        // Retrieve user ID and token from localStorage
        const userId = localStorage.getItem('Employee');
        const token = localStorage.getItem('token');

        if (!userId || !token) {
          // Handle the case where userId or token is not available
          console.error('User ID or token not found in localStorage');
          return;
        }

        // Fetch employee data based on user ID
        const response = await axios.get(`https://backendint.marsbpo.org/auth/employees/getEmployeeById/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setEmployeeData(response.data);
      } catch (error) {
        // Handle errors
        console.error('Error fetching employee data:', error);
      }
    };

    fetchEmployeeData();
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    navigate('/login');
  };

  return (
    <div className="container mx-auto mt-10">
      <h1 className="text-2xl font-semibold mb-4">Employee Dashboard</h1>
      {employeeData && (
        <div>
          <p>
            Welcome, {employeeData.name} ({employeeData.role})
          </p>
          {/* Display other employee details based on employeeData */}
        </div>
      )}
      <button
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4"
        onClick={handleLogout}
      >
        Logout
      </button>
    </div>
  );
};

export default Home;
