import React, { useState, useEffect } from "react";
import axios from "axios";
import html2pdf from "html2pdf.js";
import ReactPaginate from "react-paginate";
import logo from "../assets/logo.png"
const Reports = () => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [attendanceDetails, setAttendanceDetails] = useState([]);
  const [error, setError] = useState("");
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false); // New state for loader
  const itemsPerPage = 30;

  const handleSearch = async () => {
    try {
      if (!startDate || !endDate || !selectedDepartment) {
        setError("Please fill in all fields and select a department.");
        return;
      }

      const payload = { startDate, endDate, department: selectedDepartment }; // Include selected department in the payload
      const apiUrl =
        "https://backendint.marsbpo.org/api/attendance/getAttendanceDetailsForAllEmployees";
      const token = localStorage.getItem("token");

      if (!token) {
        console.error(
          "Token not found in localStorage. Redirect to login or handle accordingly."
        );
        return;
      }

      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      const response = await axios.post(apiUrl, payload, { headers });
      setAttendanceDetails(response.data);
      if (attendanceDetails.length === 0) {
        setError("No record found");
        return;
      }

      setError("");
      setIsDataFetched(true);
    } catch (error) {
      setError("Error fetching data. Please try again.");
    }
  };

  const generatePDF = async () => {
    setIsLoading(true); // Set loading state to true
    try {
      // Generate PDF content
      const content = generatePDFContent();

      // Create element to append content
      const element = document.createElement("div");
      element.innerHTML = content;

      // Generate PDF
      await html2pdf(element, {
        margin: 1, // Set margins here
        filename: "attendance_report.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { dpi: 192, letterRendering: true },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      });

      setIsLoading(false); // Set loading state to false after PDF generation
    } catch (error) {
      setIsLoading(false); // Set loading state to false in case of error
      console.error("Error generating PDF:", error);
    }
  };

  const generatePDFContent = () => {
    const today = new Date().toLocaleDateString(); // Get today's date
    let content = `
      <!DOCTYPE html>
      <html lang="en">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Attendance Report</title>
        <style>
          body {
            font-family: sans-serif;
          }
  
          .header {
            text-align: center;
            margin-bottom: 30px;
          }
  
          .logo {
            width: 100px;
            height: 100px;
            margin-bottom: 20px;
          }
  
          .date {
            font-weight: bold;
            margin-bottom: 10px;
          }
  
          table {
            width: 100%;
            border-collapse: collapse;
            margin: 0 auto;
          }
  
          th, td {
            padding: 10px;
            border: 1px solid #ddd;
            text-align: left;
          }
  
          th {
            background-color: #f2f2f2;
            font-weight: bold;
          }
        </style>
      </head>
      <body>
        <div class="header">
          <img src="${logo}" alt="Logo" class="logo">
          <p class="date">Date: ${today}</p>
          <h1>Attendance Report</h1>
        </div>
  
        `;

    if (attendanceDetails.length > 0) {
      content += `
        <table>
          <thead>
            <tr>
              <th>Employee Name</th>
              <th>Date</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
      `;

      attendanceDetails.forEach((attendance) => {
        content += `
          <tr>
            <td>${attendance.name}</td>
            <td>${attendance.date}</td>
            <td>${attendance.status}</td>
          </tr>
        `;
      });

      content += `
          </tbody>
        </table>
      `;
    } else {
      content += "<p>No attendance data available.</p>"; // Message for empty data
    }

    content += `
      </body>
      </html>
    `;

    return content;
  };

  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          "https://backendint.marsbpo.org/api/attendance/getDepartments",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setDepartments(response.data);
        console.log(response)
      } catch (error) {
        console.error(error);
        setError("Error fetching departments. Please try again.");
      }
    };

    fetchDepartments();
  }, []);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  return (
    <div className="lg:w-3/4 md:w-3/4 mx-auto p-4 mt-12">
      <div className="bg-white border-t-4 border-blue-950 rounded-lg overflow-hidden shadow-md hover:shadow-lg transition duration-300 ease-in-out">
        <div className="p-4">
          <div
            id="report-container"
            className="grid grid-cols-1 md:grid-cols-2 gap-4 items-center"
          >
            <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
              <div>
                <label className="mr-2">Start Date:</label>
                <input
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  className="border rounded p-2"
                />
              </div>
              <div>
                <label className="mr-2">End Date:</label>
                <input
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  className="border rounded p-2"
                />
              </div>
              
              <div className="w-full md:w-auto">
  <label className="block md:inline-block mr-2">Select Department</label>
  <div className="mr-2 border rounded">
    <select
      value={selectedDepartment}
      onChange={(e) => setSelectedDepartment(e.target.value)}
      className="py-2 px-3 text-gray-700 bg-gray-200 rounded w-full md:w-auto focus:outline-none"
    >
      <option value="select department">Select Department</option>
      {departments.map((department, index) => (
        <option
          key={`${index}-${department.department}`}
          value={department.department}
          className="text-black"
        >
          {department.department}
        </option>
      ))}
    </select>
  </div>
</div>

            </div>
            <div className="flex justify-end">
              <button
                onClick={handleSearch}
                className="bg-blue-950 text-white rounded px-4 py-2"
              >
                Search
              </button>
              {isDataFetched &&
                !isLoading && ( // Only show button when data is fetched and not loading
                  <button
                    onClick={generatePDF}
                    className="bg-green-500 text-white rounded px-4 py-2 ml-4"
                  >
                    Generate PDF Report
                  </button>
                )}
              {isLoading && ( // Show loader while generating PDF
                <div className="loader"></div>
              )}
            </div>
          </div>

          {error && (
            <div className="mt-4 text-red-500">
              <p>{error}</p>
            </div>
          )}

          {isDataFetched && (
            <div className="mt-4">
              <h2 className="text-lg font-semibold mb-2">
                Attendance Details:
              </h2>
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Employee Name
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Date
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {attendanceDetails
                      .slice(
                        currentPage * itemsPerPage,
                        (currentPage + 1) * itemsPerPage
                      )
                      .map((attendance, index) => (
                        <tr key={index}>
                          <td className="px-6 py-4 whitespace-nowrap">
                            {attendance.name}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            {attendance.date}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            {attendance.status}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                pageCount={Math.ceil(attendanceDetails.length / itemsPerPage)}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                previousLinkClassName={"pagination__link"}
                nextLinkClassName={"pagination__link"}
                disabledClassName={"pagination__link--disabled"}
                activeClassName={"pagination__link--active"}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Reports;
