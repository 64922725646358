import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import human from "../assets/human.jpg";
import Animation from "./Animations/BackgroundAnimation";

const Signup = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSignup = async () => {
    try {
      setIsLoading(true);
      setError("");

      const response = await fetch(`https://backendint.marsbpo.orgs/api/auth/signup`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password }), // Include role in the request body
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Registration failed");
      }

      navigate("/");
    } catch (error) {
      console.error("Error during registration:", error.message);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Animation />
      <div className="min-w-screen relative min-h-screen bg-gray-900 flex items-center justify-center px-5 py-5">
        <div
          className="bg-gray-100 text-gray-500 rounded-3xl shadow-xl w-full overflow-hidden"
          style={{ maxWidth: 800 }}
        >
          <div className="md:flex w-full">
            <div className="hidden md:block w-1/2 bg-indigo-500 ">
              <img
                src={human}
                alt="signup"
                className="w-full h-full object-cover"
              />
            </div>

            <div className="w-full md:w-1/2 py-10 px-5 md:px-10">
              <div className="text-center mb-10">
                <h1 className="font-bold text-3xl text-gray-900">REGISTER</h1>
                <p>Enter your information to register</p>
              </div>
              <div>
                {error && (
                  <div className="text-red-500 mb-4 text-center">{error}</div>
                )}
                <div className="flex -mx-3 mb-5">
                  <div className="w-full px-3">
                    <label
                      htmlFor="username"
                      className="text-xs font-semibold px-1"
                    >
                      UserName
                    </label>
                    <div className="flex">
                      <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center">
                        <i className="mdi mdi-account-outline text-gray-400 text-lg"></i>
                      </div>
                      <input
                        type="text"
                        id="username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        className="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500"
                        placeholder="John"
                      />
                    </div>
                  </div>
                </div>
                <div className="flex -mx-3 mb-5">
                 
                </div>
                <div className="flex -mx-3 mb-12">
                  <div className="w-full px-3">
                    <label
                      htmlFor="password"
                      className="text-xs font-semibold px-1"
                    >
                      Password
                    </label>
                    <div className="flex">
                      <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center">
                        <i className="mdi mdi-lock-outline text-gray-400 text-lg"></i>
                      </div>
                      <input
                        type={showPassword ? "text" : "password"}
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500"
                        placeholder="************"
                      />
                      <button
                        className="ml-2 focus:outline-none"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? (
                          <i className="mdi mdi-eye-off-outline"></i>
                        ) : (
                          <i className="mdi mdi-eye-outline"></i>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
                <div className="flex -mx-3 mb-5">
                  <button
                    onClick={handleSignup}
                    disabled={isLoading}
                    className={`block w-full max-w-xs mx-auto bg-indigo-500 ${
                      isLoading ? "opacity-50 cursor-not-allowed" : "hover:bg-indigo-700 focus:bg-indigo-700"
                    } text-white rounded-lg px-3 py-3 font-semibold`}
                  >
                    {isLoading ? "Signing Up..." : "REGISTER NOW"}
                  </button>
                </div>
                <Link to={"/"} className="block text-center text-gray-500">
                  Already have an account? Login here!
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
