import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Config = () => {
  const [config, setConfig] = useState({
    ip: "",
    port: "",
    inport: ""
  });

  useEffect(() => {
    fetchConfig();
  }, []);

  const fetchConfig = async () => {
    try {
      const response = await fetch("https://backendint.marsbpo.org/api/config/getconfig");
      if (response.ok) {
        const data = await response.json();
        setConfig(data);
      } else {
        console.error("Failed to fetch config:", response.statusText);
        toast.error("Failed to fetch configuration.");
      }
    } catch (error) {
      console.error("Error fetching config:", error);
      toast.error("Error fetching configuration.");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setConfig((prevConfig) => ({
      ...prevConfig,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("https://backendint.marsbpo.org/api/config/setconfig", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(config)
      });
      if (response.ok) {
        toast.success("Configuration updated successfully.");
      } else {
        console.error("Failed to update configuration:", response.statusText);
        toast.error("Failed to update configuration.");
      }
    } catch (error) {
      console.error("Error updating configuration:", error);
      toast.error("Error updating configuration.");
    }
  };

  return (
    <div className="min-h-screen bg-white-100 flex items-center justify-center">
      <ToastContainer />
      <main className="bg-white p-6 sm:p-10 shadow-lg rounded-lg space-y-6 w-full max-w-lg">
        <div className="text-center">
          <h1 className="text-4xl font-semibold mb-4 text-gray-800">ZKTeco Device</h1>
          <p className="text-gray-600">Configuration Settings</p>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="space-y-6">
            <div>
              <label htmlFor="ip" className="block text-sm font-medium text-gray-700">IP Address:</label>
              <input
                type="text"
                id="ip"
                name="ip"
                value={config.ip}
                onChange={handleChange}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
            <div>
              <label htmlFor="port" className="block text-sm font-medium text-gray-700">Port:</label>
              <input
                type="text"
                id="port"
                name="port"
                value={config.port}
                onChange={handleChange}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
            <div>
              <label htmlFor="inport" className="block text-sm font-medium text-gray-700">Inport:</label>
              <input
                type="text"
                id="inport"
                name="inport"
                value={config.inport}
                onChange={handleChange}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
            <button
              type="submit"
              className="w-full bg-blue-950 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Save Configuration
            </button>
          </div>
        </form>
      </main>
    </div>
  );
};

export default Config;
